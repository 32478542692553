body {
  margin: 0px !important;
}

.ra-field-50 {
  width: 48% !important;
  display: inline-grid;

  clear: both;
}
.ra-field-100 {
  width: 96% !important;

  display: inline-grid;
  clear: both;
}

.ra-field-clear {
  clear: both;
}
.ra-field-30 {
  width: 31%;

  /*float: left;*/
  display: inline-grid;
  clear: both;
}

.ra-inline {
  /* parent element */
  display: -webkit-flexbox;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
@supports (flex-wrap: wrap) {
  .ra-inline {
    display: flex;
  }
}

.ra-container {
  min-width: 400px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 576px) {
  .ra-container {
    min-width: 420px;
  }
}
@media (min-width: 768px) {
  .ra-container {
    min-width: 420px;
  }
}
@media (min-width: 992px) {
  .ra-container {
    min-width: 560px;
  }
}

@media (min-width: 1200px) {
  .ra-container {
    min-width: 640px;
  }
}
